export const routes = [
    //题单
    {
        name: '题单',
        path: "/problemList",
        component: () => import('@/views/problemList/index.vue'),
    },
    //题单操作
    {
        name: '',
        path: "/problemListOperate",
        redirect: '/problemList/newProblemList',
        component: () => import('@/views/problemList/problemListOperate.vue'),
        children: [{
            name: '题单设置',
            path: "/problemList/newProblemList",
            component: () => import('@/views/problemList/newProblemList.vue'),
        },
        ]
    },

    // 新题单
    { name: 'teachingQuesList',meta:{title:'题单',props:{priexRoute:'questList'}},path: 'problemList',redirect: '/problemList/questList/list'
            ,component: {template:"<router-view></router-view>"}
            ,children:[
                { name: 'teachingQuestListList',meta:{title:'题单列表'},path: 'list',component: () => import('@/views/problemList/questList/list.vue') },
                { name: 'teachingQuestListInfo',meta:{title:'题单详情'},path: ':list_id(\\d+)/info',component: () => import('@/views/problemList/questList/detail.vue') },
                { name: 'teachingQuestListCensus',meta:{title:'题单统计详情'},path: 'census/:list_id(\\d+)?',component: () => import('@/views/problemList/questList/census.vue') },
                { name: 'teachingQuestListQuestion',meta:{title:'题单题目管理'},path: ':list_id(\\d+)/question',component: () => import('@/views/problemList/questList/chooseList.vue') },
            ]
        },
    {
        name: '题单预览',
        path: "/problemList/preview",
        component: () => import('@/views/problemList/preview.vue'),
    },
    { name: 'teachingQuestListCreate',meta:{title:'添加题单',login:true},path: '/problemList/questList/save', component: () => import('@/views/problemList/questList/save.vue')  },
    { name: 'teachingQuestListEdit',meta:{title:'编辑题单',login:true},path: '/problemList/questList/:list_id(\\d+)/save', component: () => import('@/views/problemList/questList/save.vue')  },
    {
        name: '题单列表',
        path: "/problemList/myProblemList",
        component: () => import('@/views/problemList/myProblemList.vue'),
    },
    {
        name: '题单列表',
        path: "/problemList/myProblemList1",
        component: () => import('@/views/problemList/myProblemList1.vue'),
    },


    
    // 答完详情页
    {
        name: '答题详情',
        path: "/problemList/overDetails",
        component: () => import('@/views/problemList/overDetails.vue'),
    },
    {
        name: '答题题目详情',
        path: "/problemList/overTopicDetails",
        component: () => import('@/views/problemList/overTopicDetails.vue'),
    },
    {
        name: '做题详情',
        path: "/problemList/answerDetails",
        component: () => import('@/views/problemList/answerDetails.vue'),
    },
    //学生 题单详情
    {
        name: '题单详情',
        path: "/problemList/student/problemListDetails",
        component: () => import('@/views/problemList/student/problemListDetails.vue'),
    },
    //学生 模拟考试
    {
        name: '题单答题',
        //path: "/problemList/student/practiceExam",
        path: "/problemList/questAnswer",
        component: () => import('@/views/problemList/student/practiceExam.vue'),
    },
    //学生 做题结果
    {
        name: '做题结果',
        path: "/problemList/student/result",
        component: () => import('@/views/problemList/student/result.vue'),
    },
    //学生 转存题单
    {
        name: '转存题单',
        path: "/problemList/student/cacheProblemList",
        component: () => import('@/views/problemList/student/cacheProblemList.vue'),
    },
    {
        name: '答题记录',
        path: "/problemList/tiDanRecord",
        meta:{title:'答题记录',login:true},
        component: () => import('@/views/problemList/tiDanRecord.vue'),
    },
]